import axios from 'axios';
import config from '../config';
import {createAuth} from '../Helpers';

const WINTERDUTY_GETALL_RESPONSE = 'WINTERDUTY_GETALL_RESPONSE';

const initialState = {
    winterDuties : [ ]
};

export const actionCreators = {
    getAll: () => async(dispatch, getState) => {
        axios({
            method: 'GET',
            url: `${config.server}/api/winterduties`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            dispatch({type: WINTERDUTY_GETALL_RESPONSE, data: response.data})
        }).catch(error => {

        });
    },
    getAllVintermanToday: () => async(dispatch, getState) => {
        axios({
            method: 'GET',
            url: `${config.server}/api/vinterman/getAll`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            dispatch({type: WINTERDUTY_GETALL_RESPONSE, data: response.data})
        }).catch(error => {

        });
    }
};

const reducerMethods = {
    WINTERDUTY_GETALL_RESPONSE: (state, action) => {
        return{
            ...state,
            winterDuties: action.data
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};