import React, {Component} from "react";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {actionCreators as routerActions} from '../../store/RouterActions';
import {actionCreators as winterActions} from '../../store/WinterDutyStore';
import {actionCreators as plannedItemActions} from '../../store/PlannedItemStore';
import {actionCreators as personActions} from '../../store/PersonStore';
import {OfficePlan} from "../../Models/OfficePlan";
import moment, {duration} from "moment";


interface Props{
    roomCalendar: {room: {id: number, name: string, subText: string}, calendar: OfficePlan[]};
    state: any;
    maxMettings: number;
}

class MeetingRoom extends Component<Props> {

    

    render(){
        const props = this.props;
        const roomCalendar = props.roomCalendar;
        if(!roomCalendar) return null;
        const items = roomCalendar.calendar;
        const meetingsMax = props.maxMettings;

        const meetingsToday = items.length;
        let sumMinutes = 0;
        items.map(x => sumMinutes += duration(moment(x.end).diff(moment(x.start))).minutes());
        const avgMinutes = sumMinutes / meetingsToday || 0;

        const date = moment();
        let itemsToRender = [...items];
        if(itemsToRender.length > meetingsMax){
            let itemsBefore = itemsToRender.filter(x => !date.isBefore(x.end));
            itemsToRender = itemsToRender.filter(x => date.isBefore(x.end));

            if(itemsToRender.length > meetingsMax){
                itemsToRender = itemsToRender.slice(0,5);
            }
            else{
                while(itemsToRender.length < meetingsMax && itemsBefore.length > 0){
                    const next = itemsBefore.pop();
                    next && itemsToRender.unshift(next);
                }
            }
        }

        return (
            <div className='meeting-room'>
                <div className='meeting-top'>
                    <div>
                        <div className="name" >{roomCalendar.room.name}</div>
                        <div className="title" >{roomCalendar.room.subText}</div>
                    </div>
                    <div>
                        <div className='meeting-count'>Møder idag: {meetingsToday}</div>
                        <div className='meeting-time'>Gns. tid: {avgMinutes}min</div>
                    </div>
                </div>
                <div className='meetings'>
                    {itemsToRender && itemsToRender.map(x =>
                        <div key={`${x.start}${x.end}${x.subject}`} className='meeting'>
                            <div className='time'>{`${moment(x.start).format("HH:mm")}-${moment(x.end).format("HH:mm")}`}</div>
                            <div className='subject'>{x.subject}</div>
                        </div>
                    )}
                    {meetingsMax < meetingsToday && <div>...</div>}
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({state: state}),
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch),
        winterActions: bindActionCreators(winterActions, dispatch),
        personActions: bindActionCreators(personActions, dispatch),
        plannedItemActions: bindActionCreators(plannedItemActions, dispatch)
    })
)(MeetingRoom);


