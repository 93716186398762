import axios from 'axios';
import config from '../config';
import * as SlideStore from './SlideStore';
import {createAuth} from '../Helpers';

export const reducerName = 'gridState';

const GRID_SET_BIGVIEW = 'GRID_SET_BIGVIEW';
const GRID_GET_lAYOUT_REQUEST = 'GRID_GET_lAYOUT_REQUEST';
const GRID_GET_LAYOUT_RESPONSE = 'GRID_GET_LAYOUT_RESPONSE';


const initialState = {
    bigView: null,
    gridLayout: null,
    ui:{
        loadingGridLayout: false
    }
};



export const actionCreators = {
    setBigView: bigView => async(dispatch, getState) => {
        if(bigView === getState().gridState.bigView) bigView = null;
        dispatch({type: GRID_SET_BIGVIEW, view: bigView});
    },
    getGridLayoutRequest: id => async(dispatch, getState) => {
        dispatch({type: GRID_GET_lAYOUT_REQUEST});

        axios({
            method: 'GET',
            url:`${config.server}/api/grids/${id}`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            const gridLayout = response.data;
            gridLayout.cells.forEach(x => {
                if(x.type === 2){
                    dispatch(SlideStore.actionCreators.getSlideShow(x.slideShowId));
                }
            });
            dispatch({type: GRID_GET_LAYOUT_RESPONSE, data: response.data});
        }).catch(error =>{

        });
    }
};


const reducerMethods = {
    GRID_SET_BIGVIEW : (state, action) => {
        return{
            ...state,
            bigView: action.view
        }
    },
    GRID_GET_lAYOUT_REQUEST: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                loadingGridLayout: true
            }
        }
    },
    GRID_GET_LAYOUT_RESPONSE: (state, action) => {
        return{
            ...state,
            gridLayout: action.data,
            ui:{
                ...state.ui,
                loadingGridLayout: false
            }
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};