import axios from 'axios';
import config from '../config';
import {createAuth} from "../Helpers";


export const reducerName = 'teamState';

const TEAM_GETALL_REQUEST = 'TEAM_GETALL_REQUEST';
const TEAM_GETALL_RESPONSE = 'TEAM_GETALL_RESPONSE';

const initialState = {
    teams: [],
    loading: {
        getAll: false
    }
};

export const actionCreators = {
    getAll: () => async(dispatch, getState) => {
        dispatch({type: TEAM_GETALL_REQUEST});

        axios({
            method: 'GET',
            url:`${config.server}/api/team`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then((response) => {
            const data = response.data;
            dispatch({type: TEAM_GETALL_RESPONSE, data: data});
        }).catch((data) =>{
            console.log('ERROR FETCHING TEAMS', data);
        });
    }
};

const reducerMethods = {
    TEAM_GETALL_REQUEST: (state, action) => {
        return {
            ...state,
            loading:{
                getAll: true
            }
        }
    },
    TEAM_GETALL_RESPONSE: (state, action) => {
        return{
            ...state,
            teams: action.data,
            loading: {
                ...state.loading,
                getAll: false,
            }
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};