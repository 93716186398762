import React, {Component} from 'react';
import './Person.css'
import config from '../../config.json';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {actionCreators as routerActions} from '../../store/RouterActions';
import {actionCreators as winterActions} from '../../store/WinterDutyStore';
import {actionCreators as plannedItemActions} from '../../store/PlannedItemStore';
import {actionCreators as personActions} from '../../store/PersonStore';

import flagImage from '../../images/flag.png';
import moment from 'moment';
import {ColorCode} from "../../Models/ColorCode";
import {OfficePlan} from "../../Models/OfficePlan";
import {Person} from "../../Models/Person";

interface Props{
    person: Person;
    state: any;
}

class OfficePerson extends Component<Props> {

    render(){
        const props = this.props;
        const person = props.person;
        const allPlans = props.state.office365State.personCalendars as Array<{calendar: OfficePlan[], person: Person}>;
        const colorCodes = props.state.plannedItemState.colorCodes as Array<ColorCode>;

        if(!person) return null;

        const date = moment();
        const item = allPlans.find(x => x.person.id === person.id)?.calendar.find(x => date.isAfter(x.start) && date.isBefore(x.end));

        const checkColor = colorCodes && colorCodes.find((x ) => x.systemName === 'checked');
        const uncheckColor = colorCodes && colorCodes.find((x) => x.systemName === 'unchecked');

        let className = 'person clearfix';
        let style;

        if(item){
            className += ' circle checked';
            style = { borderColor:`${checkColor?.hexColor || '#ed1c24'}`, borderRadius: '50%', borderStyle: 'solid'};
        }
        else{
            className += ' circle unchecked';
            style = { borderColor:`${uncheckColor?.hexColor || '#39b54a'}`, borderRadius: '50%', borderStyle: 'solid'};
        }

        const isLoading = false;
        const profileImageClass = isLoading ? 'profile-img loading' : 'profile-img';

        const birthDate = person.birthDate ? moment(person.birthDate) : null;
        const isB = birthDate && birthDate.isSame(moment().year(birthDate.year()), 'd');
        const celebrateComp = isB ? <img className="birthday" src={flagImage} alt=''/> : '';

        return (
            <div className={className}>
                <div className="profile clearfix" >
                    {person && person.imagePath &&
                    <img className={profileImageClass} src={config.server + person.imagePath} alt="" style={style ? style : {}}/>
                    }
                    {celebrateComp}
                </div>
                <div className="flytext">{person.name}</div>
                <div className="flytext-title">{person.title}</div>
            </div>
        );
    }
}

export default connect(
    state => ({state: state}),
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch),
        winterActions: bindActionCreators(winterActions, dispatch),
        personActions: bindActionCreators(personActions, dispatch),
        plannedItemActions: bindActionCreators(plannedItemActions, dispatch)
    })
)(OfficePerson);


