import config from '../../config';
import { connect } from 'react-redux';
import * as PersonStore from '../../store/PersonStore';
import React from 'react';
import PropTypes from 'prop-types';

const BookingPerson = props => {
    const p = props.person;
    if(!p) return null;


    return(
        <div className='person' onClick={() => props.onClick(p.id)}>
            <img src={`${config.server}/${p.imagePath}`} alt='Person billede' />
            <div className='person-name'>{p.name}</div>
        </div>
    )
};

BookingPerson.propTypes = {
    person: PropTypes.object,
    onClick: PropTypes.func
};

export default connect(
    state => ({ personState: state[PersonStore.reducerName]}),
)(BookingPerson);