import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import config from '../../config';
import connect from 'react-redux/es/connect/connect';
import * as ResourceStore from '../../store/ResourceStore';
import unitBookImage from '../../images/unit_book.png';
import unitPlanImage from '../../images/unit_plan.png';
import {bindActionCreators} from 'redux';

const Unit = props => {
    const unit = props.unit;
    if(!unit) return null;
    const currentBooking = props.currentBooking;
    const unitMenuId = props.resState.unitMenuId;
    const nextBooking = props.nextBooking;
    const nextBookStart = nextBooking ? moment(nextBooking.startTime) : false;
    const currentBookingEnd = currentBooking && moment(currentBooking.endTime);

    let text = 'Ingen fremtidig booking fundet';
    if(currentBooking) text = `Booket indtil: ${currentBookingEnd.format('LLLL')} (${currentBookingEnd.fromNow()})`;
    else if(nextBooking) text = `${nextBookStart.format('LLLL')} (${nextBookStart.fromNow()})`;

    const unitMenu = () => {
        const now = moment();
        const nowPlus2 = moment();
        const endOfDay = moment();
        const todayMornig = moment();
        const todayEvening = moment();
        nowPlus2.add(2, 'hours');
        endOfDay.endOf('day');
        todayMornig.hour(7).minute(0);
        todayEvening.hour(15).minute(0);
        return(
            <div className='unit-menu'>
                <div className='unit-book' onClick={() => {
                    props.resourceActions.setBookingField('unitId', unitMenuId);
                    props.resourceActions.setBookingField('startTime', now.format('YYYY-MM-DD HH:mm'));
                    props.resourceActions.setBookingField('endTime', endOfDay.format('YYYY-MM-DD HH:mm'));
                    props.resourceActions.showTeamMenu(true);
                }}
                >
                    <img className='unit-book-image' src={unitBookImage} alt={''}/>
                    <div className='unit-book-text'>Book idag</div>

                </div>

                <div className='unit-plan' onClick={() => {
                    props.resourceActions.setBookingField('unitId', unitMenuId);
                    props.resourceActions.setBookingField('startTime', todayMornig.format('YYYY-MM-DD HH:mm'));
                    props.resourceActions.setBookingField('endTime', todayEvening.format('YYYY-MM-DD HH:mm'));
                    props.resourceActions.showTimeMenu(true);
                }}>
                    <img className='unit-plan-image' src={unitPlanImage} alt={''}/>
                    <div className='unit-plan-text'>Planlæg</div>
                </div>
            </div>
        )
    };

    return(
        <div className={`unit${currentBooking ? ' booked' : ''}`} onClick={() => props.resourceActions.unitMenu(unit.id, props.resState.unitMenuId !== unit.id)}>
            <img src={`${config.server}${unit.imagePath}`} alt=''/>
            <div className='unit-description'>
                {!props.hideName && <p className='unit-name'>{unit.name}</p>}
                {!props.hideText && <p>{text}</p>}
            </div>
            {!props.noMenu && unitMenuId === unit.id && unitMenu()}
        </div>
    );
};


Unit.propTypes = {
    unit: PropTypes.object.isRequired,
    nextBooking: PropTypes.object,
    currentBooking: PropTypes.object,
    noMenu: PropTypes.bool,
    hideText: PropTypes.bool,
    hideName: PropTypes.bool
};

export default connect(
    state => ({resState: state[ResourceStore.reducerName]}),
    dispatch => ({
        resourceActions: bindActionCreators(ResourceStore.actionCreators, dispatch),
    })
)(Unit);