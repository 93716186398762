import axios from 'axios';
import config from '../config';
import {createAuth} from "../Helpers";
import moment from "moment";

const initialState = {
    personCalendars: [],
    roomCalendars: []
};

const OFFICE365_CALENDAR_GET_ALL_RESPONSE = 'OFFICE365_CALENDAR_GET_ALL_RESPONSE';
const OFFICE365_CALENDAR_ROOM_GET_ALL_RESPONSE = 'OFFICE365_CALENDAR_ROOM_GET_ALL_RESPONSE';

export const actionCreators = {
    getAllToday: () => async(dispatch, getState) => {
        let startDate = new Date();
        startDate.setHours(0);
        startDate.setMinutes(0);
        let endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 1);

        const start = startDate.toISOString();
        const end = endDate.toISOString();
        axios({
            method: 'GET',
            url: `${config.server}/api/office365/users/calendars?startDateTime=${start}&endDateTime=${end}`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {

            const personCalendars = response.data.map(x => {
                const cal = x.calendar.map(y => { return { subject: y.subject, start: new Date(y.start.dateTime + 'Z'), end: new Date(y.end.dateTime + 'Z')} })
                return {person: x.person, calendar: cal }
            });

            dispatch({
                type: OFFICE365_CALENDAR_GET_ALL_RESPONSE,
                data: personCalendars
            });
        }).catch((data) =>{
            console.log('ERROR FETCHING CALENDARS', data);
        });
    },
    getAllRoomsToday: () => async(dispatch, getState) => {
        let startDate = new Date();
        startDate.setHours(0);
        startDate.setMinutes(0);
        let endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 1);

        const start = startDate.toISOString();
        const end = endDate.toISOString();
        axios({
            method: 'GET',
            url: `${config.server}/api/office365/rooms/calendars?startDateTime=${start}&endDateTime=${end}`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {

            const roomCalendars = response.data.map(x => {
                const cal = x.calendar.map(y => { return { subject: y.subject, start: moment(y.start.dateTime + 'Z'), end: moment(y.end.dateTime + 'Z')} });
                return {room: x.room, calendar: cal };
            });

            dispatch({
                type: OFFICE365_CALENDAR_ROOM_GET_ALL_RESPONSE,
                data: roomCalendars
            });
        }).catch((data) =>{
            console.log('ERROR FETCHING CALENDARS', data);
        });
    }
};

const reducerMethods = {
    OFFICE365_CALENDAR_GET_ALL_RESPONSE: (state, action) => {
        return{
            ...state,
            personCalendars: action.data
        }
    },
    OFFICE365_CALENDAR_ROOM_GET_ALL_RESPONSE: (state, action) => {
        return{
            ...state,
            roomCalendars: action.data
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};