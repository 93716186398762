import React from 'react';
import {connect} from "react-redux";
import YouTube from "react-youtube";


interface Props{
    videoFrameId: number;
    state:any;
}

const VideoFrame = (props: Props) => {
    const videoState = props.state.videoState.videos as {[id: number] : {id: number, videos: Array<{videoId: string}>}};
    const frame = videoState[props.videoFrameId];
    if(!frame) return null;



    return(
        <div className='video-frame'>
            {
                frame.videos.map(x =>
                {
                    const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
                    const r = x.videoId.match(rx);
                    let value = x.videoId;
                    if(r){
                        value =  r[1];
                    }
                    return(
                        <YouTube
                            key={value}
                            containerClassName={'video'}
                            videoId={value}
                            opts={{
                                height: '100%',
                                width: '100%',
                                playerVars:{
                                    mute: 1,
                                    autoplay: 1,
                                    loop: 1,
                                    controls: 0,
                                    playlist: value,
                                    modestbranding: 1,
                                    iv_load_policy: 3,

                                }
                            }}
                        />
                    )
                }

                )
            }
        </div>
    )
};

export default connect(
    state => ({state: state})
)(VideoFrame);