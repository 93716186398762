import React, {PureComponent} from 'react';
import moment from 'moment';
import {Day} from '../planning/Day';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import {actionCreators as plannedItemActions} from '../../store/PlannedItemStore';
import {actionCreators as calendarActions} from '../../store/CalendarStore';
import * as ResourceStore from '../../store/ResourceStore';
import Flatpickr from 'react-flatpickr';
import ResourceCalendarLine from "./ResourceCalendarLine";

class ResourceCalendar extends PureComponent{

    componentDidMount() {
        this.props.calendarActions.setStart(moment().add(0, 'days').startOf('day'));
        this.props.calendarActions.setNumberOfDays(1);
        this.props.plannedItemActions.getAllPlans();
    }

    render(){
        const props = this.props;
        const calendarState = props.state.calendarState;


        let hours = [];
        for(let i = 0; i < 24; i++){
            hours.push(moment(calendarState.days[0]).startOf('day').add(i, 'hours'));
        }

        const changeNumberOfDays = (nod) => {
            props.calendarActions.setNumberOfDays(nod);
        };

        const renderWeeks = (days) => {
            let arr = [];
            let lastWeek = null;
            let pointer = -1;

            days.forEach(d => {
                const curWeek = d.week();
                if(curWeek === lastWeek){
                    arr[pointer].count++;
                }
                else{
                    pointer++;
                    arr[pointer] = {week: curWeek, count: 1};
                }
                lastWeek = curWeek;
            });



            return arr.map((e) => {
                const style = { width: `${calendarState.dayWidth * e.count}%`};
                return <div className="dd-week" key={`${e.week}-${e.count}`} style={style}>UGE {e.week}</div>;
            } );
        };

        const renderMonth = (entry) => {
            const monthNames = ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'];
            const style = { width: `${calendarState.dayWidth * entry.count}%`};
            return(
                <div className="dd-month" key={`${entry.month}-${entry.count}`} style={style}>{monthNames[entry.month]}</div>
            );
        };

        const renderMonths = (days) => {
            let arr = [];
            let lastMonth = null;
            let pointer = -1;

            days.forEach(d => {
                const curMonth = d.month();
                if(curMonth === lastMonth){
                    arr[pointer].count++;
                }
                else{
                    pointer++;
                    arr[pointer] = {month: curMonth, count: 1};
                }
                lastMonth = curMonth;
            });

            return arr.map((e) => renderMonth(e));
        };

        const renderHours = () => {
            const arr = [
                '00:00', '01:00', '02:00', '03:00', '04:00', '05:00',
                '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
                '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
                '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
            return arr.map((i) => {
                const style = { width: `${90/24}%`};
                return <div className="dd-hour" key={i} style={style}>{i}</div>;
            } );
        };

        const calendarChooser = () => {
            return(
                <div className="calender-chooser">
                    <button className={'period-pick' + (calendarState.numberOfDays === 180 ? ' current' : '')} onClick={() => changeNumberOfDays(90)}>3 måneder</button>
                    <button className={'period-pick' + (calendarState.numberOfDays === 90  ? ' current' : '')} onClick={() => changeNumberOfDays(30)}>1 måned</button>
                    <button className={'period-pick' + (calendarState.numberOfDays === 30  ? ' current' : '')} onClick={() => changeNumberOfDays(14)}>2 uger</button>
                    <button className={'period-pick' + (calendarState.numberOfDays === 14  ? ' current' : '')} onClick={() => changeNumberOfDays(7)}>1 uge</button>
                    <button className={'period-pick' + (calendarState.numberOfDays === 1  ? ' current' : '')} onClick={() => changeNumberOfDays(1)}>Dagsoverblik</button>
                    <div className={'period-pick'}>|</div>
                    <button className={'period-pick'} onClick={() => props.calendarActions.setStart(new Date())}>Nu</button>

                    <div className="datepicker-container">
                        <Flatpickr value={moment(calendarState.start).format('YYYY-MM-DD')} onChange={d => props.calendarActions.setStart(new Date(d[0]))} className="date-picker-custom"/>
                    </div>
                </div>
            );
        };

        const dayComponents = calendarState.days.map((day, i) => <Day key={i} size={calendarState.dayWidth} day={day} onClick={props.calendarActions.setStart}/>);
        const monthComponents = renderMonths(calendarState.days);
        const weekComponents = renderWeeks(calendarState.days);
        const bookings = props.state[ResourceStore.reducerName].bookings;
        const dateView = calendarState.numberOfDays !== 1;
        const lineComponents = props.state[ResourceStore.reducerName].units.map(u =>
            <ResourceCalendarLine
                key={u.id}
                granularity={dateView ? 'day' : 'hour'}
                days={calendarState.days}
                startTime={calendarState.days[0]}
                unit={u}
                dayWidth={dateView ? 100/calendarState.numberOfDays : 100/24}
                plans={bookings.filter(p => u.id === p.unitId)}
            />
        );
        const minutesSinceMidnight = moment().diff(moment().startOf('day'), 'minutes');

        return (
            <div className="calendar">

                <div>
                    <div className="header">
                        <div className="row">
                            {monthComponents}
                            <span className="header-name"/>
                        </div>
                        <div className="row">
                            {weekComponents}
                            <span className="header-name"/>
                        </div>
                        <div className={'row c-' + (calendarState.numberOfDays)}>
                            {dayComponents}
                            <span className="header-name"/>
                        </div>
                        {calendarState.numberOfDays <= 1 ?
                            <div className={'row'}>
                                {renderHours()}
                                <span className="header-name"/>
                            </div> : null
                        }
                    </div>
                    <div className='calendar-body'>
                        {lineComponents}
                        {calendarState.numberOfDays <= 1 ? <div className='hour-line' style={{left: `calc(${minutesSinceMidnight / 1440 * 90}%)`}}/> : null }
                    </div>
                    {calendarChooser()}
                </div>
            </div>
        );

    }
}

export default connect(
    state => ({state: state}),
    dispatch => ({
        plannedItemActions: bindActionCreators(plannedItemActions, dispatch),
        calendarActions: bindActionCreators(calendarActions, dispatch),
        resourceActions: bindActionCreators(ResourceStore.actionCreators, dispatch)
    })
)(ResourceCalendar);