import axios from 'axios';
import config from '../config';
import {createAuth} from "../Helpers";
import moment from "moment";

export const reducerName = "receptionState";

const GET_SETTINGS_RESPONSE = 'GET_SETTINGS_RESPONSE';
const GET_MEETING_PERSONS_RESPONSE = 'GET_MEETING_PERSONS_RESPONSE';

const initialState = {
    lastHostUpdate: null
};

export const actionCreators = {
    getSettings: () => async(dispatch, getState) => {
        axios({
            method: 'GET',
            url: `${config.server}/api/reception/getSettings`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            const data = response.data;
            dispatch({type: GET_SETTINGS_RESPONSE, data: data});
        }).catch(error => {

        });
    },
    getPersonsWithMeetings: () => async(dispatch, getState) => {
        const lastUpdate = getState().receptionState.lastHostUpdate;
        if(!lastUpdate || moment().add(-5, "minutes").isAfter(lastUpdate)){
            axios({
                method: 'GET',
                url: `${config.server}/api/reception/GetPersonsWithMeetings`,
                withCredentials: true,
                auth: createAuth(getState)
            }).then(response => {
                const data = response.data;
                dispatch({type: GET_MEETING_PERSONS_RESPONSE, data: data});
            }).catch(error => {

            });
        }
    }
};

const reducerMethods = {
    GET_SETTINGS_RESPONSE: (state, action) => {
        return{
            ...state,
            settings: action.data
        }
    },
    GET_MEETING_PERSONS_RESPONSE: (state, action) => {
        return{
            ...state,
            hosts: action.data,
            lastHostUpdate: moment()
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};