import React, { Component } from 'react';
import './css/App.css';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import {actionCreators as routerActions} from './store/RouterActions';
import {actionCreators as winterActions} from './store/WinterDutyStore';
import {actionCreators as personActions} from './store/PersonStore';
import {actionCreators as plannedItemActions} from './store/PlannedItemStore';
import {actionCreators as teamActions} from './store/TeamStore';
import {actionCreators as slideActions} from './store/SlideStore';
import {actionCreators as tv2NewsActions} from './store/Tv2NewsStore';
import * as GridStore from './store/GridStore';
import moment from 'moment';
import OfficePerson from "./components/organization/OfficePerson";
import ReactLiveClock from "react-live-clock/lib/Component";
import MeetingRoom from "./components/organization/MeetingRoom";
import SlideShow from "./components/slides/SlideShow";
import eboLogo from './images/ebo-logo.png';
import VideoFrame from "./components/video/VideoFrame";

class Home extends Component {

    componentDidMount() {
        this.props.plannedItemActions.setInterval(moment().startOf('day'), moment().add(5).startOf('day'));
        this.props.plannedItemActions.getAllPlans();

        this.updateDates  = setInterval(() => {
            this.props.plannedItemActions.setInterval(moment().startOf('day'), moment().add(5).startOf('day'));
        }, 1000*180);
    }

    componentWillUnmount() {
        clearInterval(this.updateDates);
    }

    render() {
        return (
            <div className="App">
                <div className='clock-container'>
                    <ReactLiveClock />
                </div>
                <div className='left-containers'>
                    <div className='person-container'>
                        {Object.values(this.props.state.personState.persons)
                            .filter(x => x.id !== '612a3f50-aed6-4864-98b2-061f6ee0713d')
                            .map(x => <OfficePerson key={x.id} person={x} />)
                        }
                    </div>
                    <div className='bottom-containers'>
                        <div className='slider-container'>
                            <SlideShow slideShowId={10016} />
                        </div>
                        <div className='movie-container'>
                            <VideoFrame videoFrameId={2}/>
                        </div>
                        <div className='slider2-container'>
                            <SlideShow slideShowId={10017} />
                        </div>
                    </div>
                </div>

                <div className='right-containers'>
                    <div className='logo-container'>
                        <div className='text'>
                            <div>Velkommen til</div>
                            <div className='company'>Ebo Consult</div>
                        </div>
                        <img src={eboLogo} alt='ebo consult' />
                    </div>
                    <div className='today-text-container'>
                        I dag:
                    </div>
                    <div className='special-calendars'>
                        {this.props.state.office365State.roomCalendars.map((x,i) =>
                            <div className={`meeting-container meeting-container-${i}`} key={x.room.id}>
                                <MeetingRoom roomCalendar={x} maxMettings={i < 2 ? 4 : 11} />
                            </div>
                        )}
                    </div>
                </div>

            </div>
        );
  }
}

export default connect(
    state => ({state: state}),
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch),
        winterActions: bindActionCreators(winterActions, dispatch),
        personActions: bindActionCreators(personActions, dispatch),
        plannedItemActions: bindActionCreators(plannedItemActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        slideActions: bindActionCreators(slideActions, dispatch),
        tv2NewsActions: bindActionCreators(tv2NewsActions, dispatch),
        gridActions: bindActionCreators(GridStore.actionCreators, dispatch)
    })
)(Home);
