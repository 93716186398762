import axios from 'axios';
import config from '../config';
import {createAuth} from "../Helpers";


const SLIDE_GET_RESPONSE = 'SLIDE_GET_RESPONSE';

const initialState = {
    slideShows: {}
};

export const actionCreators = {
    getSlideShow: (id) => async(dispatch, getState) => {
        axios({
            method: 'GET',
            url: `${config.server}/api/slideShow/${id}`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            const data = response.data;
            dispatch({type: SLIDE_GET_RESPONSE, data: data});
        }).catch(error =>{

        });
    }
};

const reducerMethods = {
    SLIDE_GET_RESPONSE: (state, action) => {
        return{
            ...state,
            slideShows:{
                ...state.slideShows,
                [action.data.id]: action.data,
            }
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};