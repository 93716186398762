import {actionCreators as planActions} from './PlannedItemStore';
import moment from 'moment';

export const reducerName = 'calendarState';

const CALENDAR_SET_START = 'CALENDAR_SET_START';
const CALENDAR_SET_NUMBER_OF_DAYS = 'CALENDAR_SET_NUMBER_OF_DAYS';
const CALENDAR_SET_DAYS = 'CALENDAR_SET_DAYS';
const CALENDAR_SET_WIDTH = 'CALENDAR_SET_WIDTH';
const CALENDAR_SET_HIGHLIGHT = 'CALENDAR_SET_HIGHLIGHT';

const getDayArray = (start, numberOfDays) => {
    let days = [];
    for (let index = 0; index < numberOfDays; index++) {
        const d = moment(start).add(index, 'days');
        days.push(d);
    }
    return days;
};

const initialState = {
    start: moment().add(-1, 'days'),
    numberOfDays: 30,
    days: getDayArray(moment().add(-1, 'days'), 30),
    dayWidth: 90/30, //5vw for menu, 10vw for name, 1.5vw margin right. Leftover 83,5
    highLightPersonId: null,
    rowNumberRendered: 0
};


export const actionCreators = {
    setNumberOfDays: (nod) => async(dispatch, getState) => {
        const start = getState()[reducerName].start;
        const days = getDayArray(start, nod);
        const dayWidth = 90/nod;
        dispatch({type: CALENDAR_SET_NUMBER_OF_DAYS, value: nod});
        dispatch({type: CALENDAR_SET_DAYS, value: days});
        dispatch({type: CALENDAR_SET_WIDTH, value: dayWidth});
        dispatch(planActions.setInterval(days[0], days[nod-1]));
        dispatch(planActions.getAllPlans());

    },
    setHighLight: (personId) => async(dispatch, getState) => {
        dispatch({type: CALENDAR_SET_HIGHLIGHT, personId});
    },
    setStart: (start) => async(dispatch, getState) => {
        const nod = getState()[reducerName].numberOfDays;
        const days = getDayArray(start, nod);
        dispatch({type: CALENDAR_SET_START, value: start});
        dispatch({type: CALENDAR_SET_DAYS, value: days});
        dispatch(planActions.setInterval(days[0], days[nod-1]));
        dispatch(planActions.getAllPlans());
    },
    setRendered: (number) => async(dispatch,getState) => {

    }
};

const reducerMethods = {
    CALENDAR_SET_HIGHLIGHT: (state, action) => {
        return{
            ...state,
            highLightPersonId: action.personId
        }
    },
    CALENDAR_SET_START: (state, action) => {
        return{
            ...state,
            start: action.value
        }
    },
    CALENDAR_SET_NUMBER_OF_DAYS: (state, action) => {
        return{
            ...state,
            numberOfDays: action.value
        }
    },
    CALENDAR_SET_DAYS: (state, action) => {
        return{
            ...state,
            days: action.value
        }
    },
    CALENDAR_SET_WIDTH: (state, action) => {
        return{
            ...state,
            dayWidth: action.value
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};