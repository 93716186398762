import axios from 'axios';
import config from '../config';
import {createAuth} from "../Helpers";

export const reducerName = 'documentStore';

const DOCUMENT_GET_ALL_REQUEST = 'DOCUMENT_GET_ALL_REQUEST';
const DOCUMENT_GET_ALL_RESPONSE = 'DOCUMENT_GET_ALL_RESPONSE';
const DOCUMENT_SET_ACTIVE = 'DOCUMENT_SET_ACTIVE';

const initialState = {
    documents: [],
    activeDocument: null
};

export const actionCreators = {
    getAll: () => async(dispatch, getState) => {
        dispatch({type: DOCUMENT_GET_ALL_REQUEST});

        axios({
            method: 'GET',
            url: `${config.server}/api/documents`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            dispatch({type: DOCUMENT_GET_ALL_RESPONSE, data: response.data});
            if(getState().documentStore.activeDocument === null && response.data.length > 0)
                dispatch({type: DOCUMENT_SET_ACTIVE, doc: response.data[0]});
        }).catch(error =>{
            console.log('ERROR FETCHING DOCUMENTS', error);
        });
    },
    setActive: doc => async(dispatch, getState) => {
        dispatch({type: DOCUMENT_SET_ACTIVE, doc});
    }
};

const reducerMethods = {
    DOCUMENT_GET_ALL_RESPONSE: (state, action) => {
        return{
            ...state,
            documents: action.data,

        }
    },
    DOCUMENT_SET_ACTIVE: (state, action) => {
        return{
            ...state,
            activeDocument: action.doc,
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};