import {actionCreators as personActions} from './PersonStore';
import {actionCreators as officeActions} from "./Office365Store";
import {actionCreators as slideActions} from "./SlideStore";
import {actionCreators as videoActions} from "./VideoStore";

export const reducerName = 'appStore';

const APP_REFRESH_EVERYTHING = 'APP_REFRESH_EVERYTHING';


export const actionCreators = {
    refresh: () => async(dispatch) => {
        dispatch({type: APP_REFRESH_EVERYTHING});
        dispatch(personActions.getAllPersons());
        dispatch(slideActions.getSlideShow(10017));
        dispatch(slideActions.getSlideShow(10016));
        dispatch(videoActions.getVideoFrame(2));
    },
    refreshSlow: () => async(dispatch) => {
        dispatch(officeActions.getAllToday());
        dispatch(officeActions.getAllRoomsToday());
    }
};
